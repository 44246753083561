import { post, put, get } from 'modules/api';
import { LOCAL } from 'models/salesScopes';

const VALIDATED_ACTION_PAYLOAD = { action: 'validated_action' };
const REFUSED_ACTION_PAYLOAD = { action: 'refused_action' };

export const DEMAND_PENDING_VALIDATION = 'pending_validation';
export const DEMAND_VALIDATED = 'validated';
export const DEMAND_REFUSED = 'refused';

export const getInvitations = farmId => get(`farms/${farmId}/invitations/pending`);

export function sendInvitation({ toFarm, fromAssembly, message, scope = LOCAL }) {
    return post('invitations/', {
        hive: fromAssembly,
        farm: toFarm,
        message,
        scope,
    });
}

export function sendProposal({
    assemblyId,
    farmId,
    message,
    deliveryMean = null,
    addAllCatalog = null,
}) {
    const payload = {
        hive: assemblyId,
        farm: farmId,
        message,
        addAllCatalog,
    };

    if (deliveryMean) {
        payload.deliveryMean = deliveryMean;
    }

    return post('proposals/', payload);
}

export function acceptProposal(proposalId) {
    return put(`proposals/${proposalId}`, VALIDATED_ACTION_PAYLOAD);
}

export function declineProposal(proposalId) {
    return put(`proposals/${proposalId}`, REFUSED_ACTION_PAYLOAD);
}

export function acceptInvitation(invitationId, deliveryMean = null, addAllCatalog = null) {
    const payload = VALIDATED_ACTION_PAYLOAD;

    if (deliveryMean) {
        payload.deliveryMean = deliveryMean;
    }

    if (addAllCatalog !== null) {
        payload.addAllCatalog = addAllCatalog;
    }

    return put(`invitations/${invitationId}`, payload);
}

export function declineInvitation(invitationId) {
    return put(`invitations/${invitationId}`, REFUSED_ACTION_PAYLOAD);
}
