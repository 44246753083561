import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useI18n from 'hooks/I18n/useI18n';

import { READY, PROCESSING, FAILED } from 'modules/utils/ajaxStatuses';
import { firstFarmSelector } from 'modules/currentUser';

import FeatureService from 'models/features';
import { sendProposal as sendProposalQuery } from 'models/demand.js';

import { fetchHive } from 'api/hives';

import StructuredSidePanel from 'components/StructuredSidePanel.jsx';
import RadioButton from 'components/RadioButton.jsx';
import { Heading, Grid, Text, Button, Icon, LoadingAnimation } from 'components/ui';
import Separator from 'components/Separator.jsx';
import Avatar from 'components/Avatar.jsx';
import PlaceMapModal from 'components/Maps/PlaceMapModal.jsx';

const INITIAL_STATE = 'INITIAL';
const LOADING_STATE = 'LOADING';
const LOADED_STATE = 'LOADED';
const FAILED_STATE = 'FAILED';

const ProposalPanel = props => {
    const { assembly, onClose, farmId, setProposalAsPending } = props;
    const farm = useSelector(firstFarmSelector);
    const { trans } = useI18n();
    const textarea = useRef();
    const [state, setState] = useState({
        status: READY,
        deliveryMeanOption: 'dropping',
        catalogAvailabilityOption: 'all',
    });
    const [hive, setHive] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(INITIAL_STATE);

    const hasCatalogOption = FeatureService.isFeatureActive(
        'producer_catalog_invitation_option',
        farm
    );

    const sendProposal = (assemblyId, message) => {
        setState(prevstate => ({ ...prevstate, status: PROCESSING }));

        return sendProposalQuery({
            assemblyId,
            farmId,
            message,
            deliveryMean: state.deliveryMeanOption,
            addAllCatalog:
                state.catalogAvailabilityOption === null
                    ? null
                    : state.catalogAvailabilityOption === 'all',
        })
            .then(() => {
                setState(prevstate => ({
                    ...prevstate,
                    status: READY,
                }));
                setProposalAsPending(assemblyId);
            })
            .catch(() => {
                setState(prevstate => ({ ...prevstate, status: FAILED }));
            });
    };

    const editDeliveryMeanOption = value => {
        setState(prevstate => ({ ...prevstate, deliveryMeanOption: value }));
    };
    const editCatalogAvailabilityOption = value => {
        setState(prevstate => ({ ...prevstate, catalogAvailabilityOption: value }));
    };

    const clickOnAccept = () => {
        sendProposal(assembly.id, textarea.current.value);
    };

    useEffect(() => {
        setLoadingStatus(LOADING_STATE);
        fetchHive(assembly.id)
            .then(res => {
                setHive(res);
                setLoadingStatus(LOADED_STATE);
            })
            .catch(() => setLoadingStatus(FAILED_STATE));
    }, [assembly]);

    return (
        <>
            <StructuredSidePanel
                isOpened
                onClose={onClose}
                actions={
                    <Grid xs="6|6">
                        <Button block weight="link" onClick={onClose}>
                            {trans('global.cancel')}
                        </Button>
                        <Button
                            block
                            variant="success"
                            onClick={() => {
                                clickOnAccept(assembly.id);
                            }}
                            disabled={state.status === PROCESSING}
                            loading={state.status === PROCESSING}
                        >
                            {trans('farm.sendProposal')}
                        </Button>
                    </Grid>
                }
            >
                {loadingStatus === LOADING_STATE && <LoadingAnimation type="spinner" />}
                {loadingStatus === LOADED_STATE && (
                    <>
                        <div className="u-mb-6">
                            <Heading className="u-flexible-item u-mb-1" size={2} responsive={false}>
                                {trans('hives.horizontalBox.suggestMyProducts')}
                            </Heading>
                            <Text variant="uiMedium">{`${trans('global.assembly')} ${
                                hive.place.address.city.name
                            }`}</Text>
                            <Text variant="uiMedium">{hive.name}</Text>
                            <Button
                                noPadding
                                disabled={!hive}
                                type="button"
                                weight="link"
                                onClick={() => setIsOpen(true)}
                            >
                                {trans('global.seeOnMap')}
                            </Button>
                            <Grid className="u-mt-4 u-flexible-row" sm="2|10">
                                <Avatar rounded size="small" photoId={hive.leader.photo?.id} />
                                <div>
                                    <Text size="medium" weight="bold">
                                        {trans('user.roles.host')}
                                    </Text>
                                    <Text variant="uiMedium">{`${hive.leader.firstName} ${hive.leader.lastName} - ${hive.leader.phone}`}</Text>
                                </div>
                            </Grid>
                        </div>
                        <Separator color="maroon7" />
                        <div className="u-mb-4">
                            <Heading size={4} className="u-mb-2">
                                {trans('deliveryMean')}
                            </Heading>
                            <Text>{trans('deliveryMean.selectLabel')}</Text>
                        </div>
                        <div className="u-mb-4">
                            <RadioButton
                                className="u-mb-1"
                                inputId="dropping"
                                isSelected={state.deliveryMeanOption === 'dropping'}
                                name="deliveryMeanOption"
                                onChange={editDeliveryMeanOption}
                                value="dropping"
                                variant="circle"
                            >
                                <Text
                                    inline
                                    weight={
                                        state.deliveryMeanOption === 'dropping' ? 'bold' : 'normal'
                                    }
                                >
                                    {trans('deliveryMean.optionDropping')}
                                </Text>
                            </RadioButton>
                            <Text className="u-mb-4" color="info">
                                {trans('deliveryMean.optionDropping.info')}
                            </Text>
                            <RadioButton
                                inputId="expedition"
                                isSelected={state.deliveryMeanOption === 'expedition'}
                                name="deliveryMeanOption"
                                onChange={editDeliveryMeanOption}
                                value="expedition"
                                variant="circle"
                            >
                                <Text
                                    inline
                                    weight={
                                        state.deliveryMeanOption === 'expedition'
                                            ? 'bold'
                                            : 'normal'
                                    }
                                >
                                    {trans('deliveryMean.optionExpedition')}
                                    <Icon
                                        src={require('icons/shipping.svg')}
                                        className="u-pl-1"
                                        size="x-large"
                                    />
                                </Text>
                            </RadioButton>
                            <Text className="u-mb-2" color="info">
                                {trans('deliveryMean.optionExpedition.info')}
                            </Text>
                        </div>

                        {/* Disponibilité du catalogue */}
                        {hasCatalogOption ? (
                            <>
                                <Separator color="maroon7" />
                                <div className="u-mb-4">
                                    <Heading size={4} className="u-mb-2">
                                        {trans('farm.proposal.catalogAvailability')}
                                    </Heading>
                                    <Text>
                                        {trans('farm.proposal.catalogAvailability.description')}
                                    </Text>
                                </div>
                                <div className="u-mb-4">
                                    <RadioButton
                                        className="u-mb-1"
                                        inputId="all"
                                        isSelected={state.catalogAvailabilityOption === 'all'}
                                        name="catalogAvailabilityOption"
                                        onChange={editCatalogAvailabilityOption}
                                        value="all"
                                        variant="circle"
                                    >
                                        <Text
                                            inline
                                            weight={
                                                state.catalogAvailabilityOption === 'all'
                                                    ? 'bold'
                                                    : 'normal'
                                            }
                                        >
                                            {trans('farm.proposal.catalogAvailability.all.label')}
                                        </Text>
                                    </RadioButton>
                                    <Text className="u-mb-4" color="info">
                                        {trans('farm.proposal.catalogAvailability.all.description')}
                                    </Text>
                                    <RadioButton
                                        inputId="partial"
                                        isSelected={state.catalogAvailabilityOption === 'partial'}
                                        name="catalogAvailabilityOption"
                                        onChange={editCatalogAvailabilityOption}
                                        value="partial"
                                        variant="circle"
                                    >
                                        <Text
                                            inline
                                            weight={
                                                state.catalogAvailabilityOption === 'partial'
                                                    ? 'bold'
                                                    : 'normal'
                                            }
                                        >
                                            {trans(
                                                'farm.proposal.catalogAvailability.partial.label'
                                            )}
                                        </Text>
                                    </RadioButton>
                                    <Text className="u-mb-2" color="info">
                                        {trans(
                                            'farm.proposal.catalogAvailability.partial.description'
                                        )}
                                    </Text>
                                </div>
                            </>
                        ) : null}
                        <Separator color="maroon7" />
                        <Heading size={4} className="u-mb-2">
                            {trans('deliveryMean.proposalMessageLabel')}
                        </Heading>
                        <textarea
                            className="fa-input u-w-100 u-mb-4"
                            name="message"
                            ref={textarea}
                            cols="30"
                            rows="7"
                            placeholder={trans(
                                'hives.box.placeHolder.messageForPropositionToLeader'
                            )}
                            defaultValue={trans('hives.box.messageForPropositionToLeader')}
                        />
                        {state.status === FAILED && (
                            <Text color="warning" className="u-mb-2">
                                {trans('global.error.retryMessage')}
                            </Text>
                        )}
                    </>
                )}
            </StructuredSidePanel>
            {hive && (
                <PlaceMapModal
                    modalTitle={trans('hiveHome.modal.map.title')}
                    placeType="assembly"
                    isOpen={isOpen}
                    entity={hive}
                    closeModal={() => setIsOpen(false)}
                    defaultZoom={13}
                />
            )}
        </>
    );
};

ProposalPanel.propTypes = {
    assembly: PropTypes.object.isRequired,
    farmId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    setProposalAsPending: PropTypes.func.isRequired,
};
export default ProposalPanel;
