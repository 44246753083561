import React, { useEffect, useState } from 'react';
import Backbone from 'backbone';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import useResponsive from 'hooks/Navigation/useResponsive.js';
import { linkTo } from 'modules/utils';
import {
    Button,
    Box,
    Grid,
    Heading,
    Paragraph,
    Text,
    Wrapper,
    LoadingAnimation,
} from 'components/ui';
import { FormSelectGroup, TextInputGroup } from 'components/ui/form';
import PageHeader from 'components/PageHeader.jsx';
import useI18n from 'hooks/I18n/useI18n.js';
import { getDistributionById, getOrdersNumbers } from 'models/distributions';
import { getVATRates } from 'models/farms';
import { postPayment } from 'models/onsite-sales';
import { captureException } from 'modules/error-reporting';
import { formatPriceToAmount } from 'modules/utils/currency.js';

const NewPaymentRequestForm = ({ farmId, match }) => {
    const { trans } = useI18n();
    const [amountIncludingTaxes, setAmountIncludingTaxes] = useState('0');
    const [vat, setVAT] = useState(0);
    const [distributionOrders, setDistributionOrders] = useState([]);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [vatRatesOptions, setVATRatesOptions] = useState([]);
    const [isSubmittingSMS, setIsSubmittingSMS] = useState(false);
    const [isSubmittingMail, setIsSubmittingMail] = useState(false);
    const [paymentError, setPaymentError] = useState('');
    const [currentDistribution, setCurrentDistribution] = useState(null);
    const isSmallWidth = useResponsive();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            getDistributionById(match.params.id),
            getOrdersNumbers(match.params.id),
            getVATRates(farmId),
        ]).then(([distribution, orders, vatRates]) => {
            setCurrentDistribution(distribution);
            const ordersOrderedByOrderNumber = R.sortBy(R.prop('number'))(orders);
            setDistributionOrders(ordersOrderedByOrderNumber);
            if (!R.isEmpty(ordersOrderedByOrderNumber)) {
                setCurrentOrder(ordersOrderedByOrderNumber[0]);
            }
            if (R.isEmpty(vatRates)) {
                setVAT(0);
                setVATRatesOptions([{ label: trans('onsite_sales.notBillingVAT'), value: '0' }]);
            } else {
                setVAT(vatRates.sort()[0]);
                setVATRatesOptions(
                    vatRates.sort().map(vatRate => {
                        return { label: `${vatRate * 100} %`, value: vatRate };
                    })
                );
            }
            setIsLoading(false);
        });
    }, [match.params.id, farmId, trans]);

    const onOrderNumberChange = (name, value) => {
        const newOrder = R.find(R.propEq('number', parseInt(value, 10)))(distributionOrders);
        setCurrentOrder(newOrder);
    };

    const sendPayment = channel => () => {
        if (channel === 'SMS') {
            setIsSubmittingSMS(true);
        } else {
            setIsSubmittingMail(true);
        }
        postPayment({
            distributionId: parseInt(match.params.id, 10),
            farmId,
            memberId: currentOrder.member.id,
            sendBy: channel,
            vatDetails: [
                {
                    amount: formatPriceToAmount(amountIncludingTaxes),
                    vatRate: vat,
                },
            ],
        })
            .then(() => {
                Backbone.history.navigate(`producers/onsite-sales/history/${match.params.id}`, {
                    trigger: true,
                });
            })
            .catch(error => {
                setIsSubmittingSMS(false);
                setIsSubmittingMail(false);
                setPaymentError(`${error.status} ${error.responseText}`);
                captureException(error);
            });
    };

    return (
        <div>
            <PageHeader
                backButtonIconOnly
                backHref={linkTo(`producers/onsite-sales/history/${match.params.id}`)}
                background="default"
                title={
                    currentDistribution
                        ? `${currentDistribution.assemblyCity} - ${currentDistribution.assemblyName}`
                        : ''
                }
            />
            <Wrapper xsPadding>
                {isLoading ? (
                    <Box>
                        <Grid align="middle">
                            <LoadingAnimation type="spinner" />
                        </Grid>
                    </Box>
                ) : (
                    <Box padding="xsmall">
                        {!isSmallWidth && (
                            <Heading className="u-mb-2" size={2}>
                                {trans('onsite_sales.newSale')}
                            </Heading>
                        )}
                        <Grid align="middle" className="u-mb-4" sm="6+">
                            <TextInputGroup
                                label={trans('onsite_sales.amountIncludingTaxes')}
                                name="amountIncludingTaxes"
                                onChange={(_, value) => {
                                    setAmountIncludingTaxes(value);
                                }}
                                value={amountIncludingTaxes}
                            />
                            <FormSelectGroup
                                label={trans('global.VAT')}
                                name="vat"
                                onChange={(_, value) => {
                                    setVAT(value);
                                }}
                                value={vat}
                                options={vatRatesOptions}
                            />
                            {!R.isEmpty(distributionOrders) && (
                                <FormSelectGroup
                                    label={trans('global.orderNumber')}
                                    name="orders"
                                    onChange={onOrderNumberChange}
                                    options={distributionOrders.map(({ number }) => ({
                                        label: `${number}`,
                                        value: `${number}`,
                                    }))}
                                />
                            )}
                            {currentOrder && (
                                <div>
                                    <Text>{`${currentOrder.member.firstName} ${currentOrder.member.lastName}`}</Text>
                                    <Text weight="bold">{currentOrder.member.phone}</Text>
                                    <Text weight="bold">{currentOrder.member.email}</Text>
                                </div>
                            )}
                        </Grid>
                        <Button
                            block
                            className="u-mb-2"
                            loading={isSubmittingSMS}
                            onClick={sendPayment('SMS')}
                            variant="success"
                        >
                            {trans('onsite_sales.payment_request_form.sendBySMS')}
                        </Button>
                        <Button
                            block
                            className="u-mb-2"
                            loading={isSubmittingMail}
                            onClick={sendPayment('Mail')}
                        >
                            {trans('onsite_sales.payment_request_form.sendByEmail')}
                        </Button>
                        <Text color="danger">{paymentError}</Text>
                        <Paragraph className="u-mb-2">
                            {trans('onsite_sales.payment_request_form.note_2')}
                        </Paragraph>
                    </Box>
                )}
            </Wrapper>
        </div>
    );
};

NewPaymentRequestForm.propTypes = {
    farmId: PropTypes.number.isRequired,
    match: PropTypes.object.isRequired,
};

export default NewPaymentRequestForm;
