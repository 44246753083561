import React from 'react';
import PropTypes from 'prop-types';
import Input from 'src/components/atoms/Input/Input.jsx';
import Text, { SMALL } from 'src/components/atoms/Text/Text.jsx';

const Autocomplete = React.forwardRef(
    (
        { placeholder, prefix, onChange, onBlur, onItemClick, suggestions, fieldValue, size },
        ref
    ) => {
        return (
            <div className="nd-autocomplete">
                <Input
                    ref={ref}
                    type="search"
                    placeholder={placeholder}
                    prefix={prefix}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={fieldValue || ref.current?.value}
                    size={size}
                />
                {suggestions.length > 0 && (
                    <div className="nd-autocomplete-suggestions">
                        {suggestions.map(suggestion => (
                            <div
                                key={suggestion.metadata.id}
                                className="nd-autocomplete-suggestions-item"
                                onMouseDown={() => onItemClick(suggestion)}
                            >
                                <Text size={SMALL}>{suggestion.text}</Text>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
);

Autocomplete.propTypes = {
    placeholder: PropTypes.string,
    fieldValue: PropTypes.string,
    prefix: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onItemClick: PropTypes.func.isRequired,
    suggestions: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            metadata: PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            }).isRequired,
        })
    ),
    size: PropTypes.string,
};

Autocomplete.defaultProps = {
    placeholder: '',
    suggestions: [],
    prefix: null,
    onBlur: () => null,
};

export default Autocomplete;
