import React, { useMemo } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    PhoneInput as LibPhoneInput,
    defaultCountries as libDefaultCountries,
    parseCountry,
    buildCountryData,
} from 'react-international-phone';

const PhoneInput = ({
    value,
    name,
    id,
    onChange,
    onBlur,
    defaultCountry,
    error,
    translatedCountries = [],
    preferredCountriesIsoCodes = ['fr', 'be', 'ch', 'de', 'es', 'it', 'nl', 'lu'],
    fullWidth = false,
    mobileFullWidth = true,
}) => {
    const translatedCountriesIndexedByCode = useMemo(() => {
        return R.indexBy(R.prop('code'), translatedCountries);
    }, [translatedCountries]);

    const defaultCountries = useMemo(() => {
        return Object.keys(translatedCountriesIndexedByCode).length > 0
            ? libDefaultCountries.map(country => {
                  const parsedCountry = parseCountry(country);
                  parsedCountry.name =
                      translatedCountriesIndexedByCode[parsedCountry.iso2.toUpperCase()]?.name ||
                      parsedCountry.name;

                  return buildCountryData(parsedCountry);
              })
            : libDefaultCountries;
    }, [translatedCountriesIndexedByCode]);

    const preferredCountries = useMemo(() => {
        return defaultCountries
            .filter(country => {
                const { iso2 } = parseCountry(country);

                return preferredCountriesIsoCodes.includes(iso2);
            })
            .sort((country1, country2) => {
                const { name: nameCountry1 } = parseCountry(country1);
                const { name: nameCountry2 } = parseCountry(country2);

                if (nameCountry1 < nameCountry2) {
                    return -1;
                }

                if (nameCountry1 > nameCountry2) {
                    return 1;
                }

                return 0;
            })
            .map(country => parseCountry(country).iso2);
    }, [preferredCountriesIsoCodes, defaultCountries]);

    const adjustDefaultCountry = () => {
        // At The Food Assembly, we use 'ca' as country code for Catalonia but in this lib,
        // it represents Canada so we have to change it to 'es'
        if (defaultCountry === 'ca') {
            return 'es';
        }

        // Quick fix to avoid using 'us'
        // as default country for people who navigate on an english version of our platform
        // (default country can be based on the user locale)
        if (defaultCountry === 'en') {
            return 'gb';
        }

        return defaultCountry;
    };

    const adjustedDefaultCountry = adjustDefaultCountry();

    return (
        <>
            <LibPhoneInput
                className={classNames('nd-phone-input', {
                    'nd-phone-input--full-width': fullWidth,
                    'nd-phone-input--mobile-full-width': !fullWidth && mobileFullWidth,
                    'nd-phone-input--error': error,
                })}
                preferredCountries={preferredCountries}
                defaultCountry={adjustedDefaultCountry}
                countries={defaultCountries}
                forceDialCode
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{
                    id,
                    name,
                }}
            />
            {error && <div className="nd-input-error-msg">{error}</div>}
        </>
    );
};

PhoneInput.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    defaultCountry: PropTypes.string,
    fullWidth: PropTypes.bool,
    mobileFullWidth: PropTypes.bool,
    error: PropTypes.string,
    preferredCountriesIsoCodes: PropTypes.arrayOf(PropTypes.string),
    translatedCountries: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
};

export default PhoneInput;
