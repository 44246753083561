import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import okIcon from 'icons/ok.svg';
import InlineSVG from 'svg-inline-react';
import Icon from 'components/Icon.jsx';

const buttonVariantToSpinnerColor = {
    success: 'spinner--green',
    primary: 'spinner--orange',
    danger: 'spinner--red',
    warning: 'spinner--beige',
};

const buttonSizeToSpinnerSize = {
    small: 'spinner--sm',
};

export class Button extends React.Component {
    static propTypes = {
        /**
         * Sometimes we just want the style of a disabled button ... so no onClick
         */
        onClick(props, propName) {
            if (!props.disabled && !props.href && typeof props[propName] !== 'function') {
                return new Error(
                    `${propName} is required to be a function if Button is not disabled and no href is provided.`
                );
            }
            return null;
        },
        onMouseDown: PropTypes.func,
        /**
         * button[disabled] = loading || disabled
         */
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
        children: PropTypes.node,
        className: PropTypes.string,
        type: PropTypes.oneOf([
            // default is submit, this is html stuff
            'submit',
            'reset',
            'button',
        ]).isRequired,
        variant: PropTypes.oneOf([
            // default is kind of white colored
            'success',
            'primary',
            'danger',
            'warning',
            'magazine',
            'highlight',
        ]),
        size: PropTypes.oneOf(['small', 'extraLarge']),
        weight: PropTypes.oneOf([
            // default is "classic" button feel
            'link',
            'outline',
            'light',
        ]),
        block: PropTypes.bool,
        flex: PropTypes.bool,
        rounded: PropTypes.bool,
        // heavy button, add the button-header which uppercase and bold the text
        heavy: PropTypes.bool,
        icon: PropTypes.string,
        iconColor: PropTypes.string,
        iconPosition: PropTypes.oneOf(['inside', 'outside']),
        iconSide: PropTypes.oneOf(['left', 'right']),
        // do not show the checkmark if dirty, show if not dirty
        dirty: PropTypes.bool,
        extended: PropTypes.bool,
        responsive: PropTypes.bool,
        bypassRouter: PropTypes.bool,
        href: PropTypes.string,
        target: PropTypes.string,
        noPadding: PropTypes.bool,
    };

    static defaultProps = {
        children: null,
        disabled: false,
        loading: false,
        block: false,
        flex: false,
        extended: false,
        responsive: false,
        type: 'submit',
        rounded: false,
        heavy: false,
        variant: null,
        weight: null,
        icon: null,
        iconColor: null,
        // used to display a checkmark if component is not dirty
        // so ... by default let's not display it ...
        dirty: true,
        href: null,
        bypassRouter: false,
        iconPosition: 'inside',
        iconSide: 'left',
    };

    renderIcon = (style, icon) => {
        const isSVGIcon = icon.slice(0, 4) === '<svg';
        if (isSVGIcon) {
            return (
                <InlineSVG
                    style={style}
                    key="icon"
                    className={classnames('svgIcon', {
                        [`icon--${this.props.iconColor}`]: this.props.iconColor,
                    })}
                    src={icon}
                />
            );
        }
        return <span style={style} key="icon" className={`icon icon-${icon}`} />;
    };

    render() {
        const {
            onClick,
            onMouseDown,
            size,
            variant,
            weight,
            loading,
            disabled,
            block,
            flex,
            rounded,
            icon,
            heavy,
            dirty,
            children,
            type,
            className,
            extended,
            responsive,
            href,
            bypassRouter,
            target,
            iconPosition,
            iconSide,
            noPadding,
        } = this.props;

        const renderedChildren = [];

        const buttonClasses = {
            [`button--${size}`]: size,
            [`button--${variant}`]: variant,
            [`button--${weight}`]: weight,
            'button--linkWithIcon': icon && weight === 'link',
            'button--iconOutside': iconPosition === 'outside',
            'button--iconOnly': icon && !children,
            'button--linkInline': icon && weight === 'link' && iconPosition === 'outside',
            'button--block': block,
            'button--flex': flex,
            'button--rounded': rounded,
            'button--extended': extended,
            'button--responsive': responsive,
            'button-header': heavy,
            'button--checkMark': !dirty && block,
            'button--noPadding': noPadding,
        };

        // spinner must be on the left in block mode
        // or replace the text (while leaving the button dimensions untouched) in non-block
        if (loading) {
            const spinnerColor = buttonVariantToSpinnerColor[variant] || 'spinner--white';
            const spinnerClasses = classnames('spinner shown', {
                [spinnerColor]: true,
                [buttonSizeToSpinnerSize[size] || 'spinner--md']: true,
            });
            renderedChildren.push(<span key="spinner" className={spinnerClasses} />);
        } else if (!dirty && block) {
            // do not display the checkmark if button is not a block
            renderedChildren.push(
                <Icon key="dirtyCheckMark" src={okIcon} color="alpha" size="large" />
            );
        }

        // visibility hidden if loading && !block so that there's still the same size
        // with position: absolute of the spinner, it works quite well :o
        const childrenNonBlockStyle = {};
        if (!block && loading) {
            childrenNonBlockStyle.visibility = 'hidden';
        }
        if (icon) {
            if (children) {
                if (iconSide === 'left') {
                    // push the icon first when icon must be visible on the left side
                    renderedChildren.push(this.renderIcon(childrenNonBlockStyle, icon));
                }
                renderedChildren.push(
                    <span style={childrenNonBlockStyle} key="children" className="button-text">
                        {children}
                    </span>
                );
                if (iconSide === 'right') {
                    // push the icon last when icon must be visible on the left side
                    renderedChildren.push(this.renderIcon(childrenNonBlockStyle, icon));
                }
            } else {
                // there are no children ... that means icon--only
                renderedChildren.push(this.renderIcon(childrenNonBlockStyle, icon));
                buttonClasses['button--icon'] = true;
            }
        } else {
            renderedChildren.push(
                <span className="button-text" style={childrenNonBlockStyle} key="children">
                    {children}
                </span>
            );
        }

        if (href) {
            return (
                <a
                    href={disabled || loading ? null : href}
                    data-bypass={bypassRouter || null}
                    className={classnames(
                        'button',
                        buttonClasses,
                        { disabled: disabled || loading },
                        className
                    )}
                    onClick={onClick}
                    onMouseDown={onMouseDown}
                    target={target}
                >
                    <span className="button-content">{renderedChildren}</span>
                </a>
            );
        }

        return (
            <button
                type={type}
                className={classnames('button', buttonClasses, className)}
                onClick={onClick}
                onMouseDown={onMouseDown}
                disabled={loading ? true : disabled}
            >
                <span className="button-content">{renderedChildren}</span>
            </button>
        );
    }
}

export default Button;
