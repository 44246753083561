import { get, post } from 'modules/api';

export function getDistributions(farmId) {
    return get(`onsite-sales/${farmId}/distributions`);
}

export function getPaymentsHistory({ distributionId, farmId }) {
    return get(`onsite-sales/farm/${farmId}/distribution/${distributionId}/payments`);
}

export function postPayment({ distributionId, farmId, memberId, vatDetails, sendBy }) {
    return post('onsite-sales/pay', {
        distributionId,
        farmId,
        memberId,
        vatDetails,
        sendBy,
    });
}
