import * as R from 'ramda';
import reducerRegistry from '../../reducers/ReducerRegistry';
import {
    NONE,
    LOADING,
    LOADED,
    FAILED,
    createApiActionTypes,
    updateKeys,
    LOGOUT,
} from '../../actions';

export const makeTemporaryOrderId = distributionId => `tempOrderId_${distributionId}`;
export const makeTemporaryOrderItemId = (distributionId, offerId) =>
    `tempOrderItemId_${distributionId}_${offerId}`;

export const GLOBAL_STATE_KEY = 'ordersCollection';
export const actionTypes = {
    CREATE_BASKET: createApiActionTypes('orders', 'CREATE_BASKET'),
    FETCH_ORDERS: createApiActionTypes('orders', 'FETCH_ORDERS'),
    FETCH_BASKET: createApiActionTypes('orders', 'FETCH_BASKET'),
    UPDATE_ORDER_ITEM: createApiActionTypes('orders', 'UPDATE_ORDER_ITEM'),
    ADD_OFFER: createApiActionTypes('orders', 'ADD_OFFER'),
    VALIDATE_ORDER: createApiActionTypes('orders', 'VALIDATE_ORDER'),
    REVALIDATE_ORDER: createApiActionTypes('orders', 'REVALIDATE_ORDER'),
    CANCEL_ORDER: createApiActionTypes('orders', 'CANCEL_ORDER'),
    RESET_ORDER_TO_CART: createApiActionTypes('orders', 'RESET_ORDER_TO_CART'),
    MISSING_USER_INFORMATION_ERROR: 'MISSING_USER_INFORMATION_ERROR',
    STOCK_ERROR: 'STOCK_ERROR',
    CLOSE_STOCK_ERROR_MODAL: 'CLOSE_STOCK_ERROR_MODAL',
};

export const ordersCollectionReducer = (
    state = {
        isInitialLoad: true,
        fetchStatus: NONE, // fetch Status of the "collection"
        ids: [],
        isCreatingBasket: false,
        byId: {},
        orders_fetchStatus: {},
        orderItems_byId: {},
        orderItems_fetchStatus: {},
    },
    { type, payload }
) => {
    switch (type) {
        case actionTypes.CREATE_BASKET.REQUEST:
            return {
                ...state,
                isCreatingBasket: true,
            };
        case actionTypes.CREATE_BASKET.SUCCESS:
            return {
                ...state,
                isCreatingBasket: false,
            };
        case actionTypes.FETCH_ORDERS.REQUEST:
            return {
                ...state,
                fetchStatus: LOADING,
            };
        case actionTypes.FETCH_ORDERS.SUCCESS:
            return {
                ...state,
                isInitialLoad: false,
                ids: payload.result,
                fetchStatus: LOADED,
                byId: R.mergeDeepRight(state.byId, payload.entities.orders),
                orders_fetchStatus: updateKeys(payload.result, LOADED, state.orders_fetchStatus),
                orderItems_byId: R.mergeDeepRight(
                    state.orderItems_byId,
                    payload.entities.orderItems
                ),
                orderItems_fetchStatus: updateKeys(
                    Object.keys(payload.entities.orderItems),
                    LOADED,
                    state.orderItems_fetchStatus
                ),
            };
        case actionTypes.FETCH_ORDERS.FAILURE:
            return {
                ...state,
                isInitialLoad: false,
                fetchStatus: FAILED,
                error: payload.error,
            };
        case actionTypes.FETCH_BASKET.REQUEST: {
            const previousEntity = state.byId[payload.entityIds[0]];
            return R.evolve(
                {
                    byId: R.mergeDeepLeft({
                        [payload.entityIds[0]]: {
                            countItems: 0,
                            distributionId: payload.distributionId,
                            status: 'basket',
                            state: 1,
                            items: R.propOr([], 'items', previousEntity),
                        },
                    }),
                    orders_fetchStatus: updateKeys(payload.entityIds, LOADING),
                },
                state
            );
        }
        case actionTypes.FETCH_BASKET.SUCCESS:
            const entityIdChanged = () => payload.previousEntityIds[0] !== payload.entityIds[0];
            return R.evolve(
                {
                    byId: R.pipe(
                        R.when(entityIdChanged, R.omit(payload.previousEntityIds)),
                        R.mergeDeepLeft(payload.entities.orders)
                    ),
                    orders_fetchStatus: R.pipe(
                        R.when(entityIdChanged, R.omit(payload.previousEntityIds)),
                        updateKeys(payload.entityIds, LOADED)
                    ),
                    orderItems_byId: R.mergeDeepLeft(payload.entities.orderItems),
                    orderItems_fetchStatus: updateKeys(
                        Object.keys(payload.entities.orderItems),
                        LOADED
                    ),
                },
                state
            );
        case actionTypes.FETCH_BASKET.FAILURE:
            return {
                ...state,
                orders_fetchStatus: updateKeys(payload.entityIds, FAILED, state.orders_fetchStatus),
            };
        case actionTypes.UPDATE_ORDER_ITEM.REQUEST: {
            const orderItemId = payload.entityIds[0];
            return {
                ...state,
                // @NOTE: handled by redux-optimist
                orderItems_byId: {
                    ...state.orderItems_byId,
                    [orderItemId]: {
                        ...state.orderItems_byId[orderItemId],
                        quantity: payload.quantity,
                    },
                },
                orderItems_fetchStatus: updateKeys(
                    payload.entityIds,
                    LOADING,
                    state.orderItems_fetchStatus
                ),
            };
        }
        case actionTypes.ADD_OFFER.REQUEST: {
            // @NOTE: this is a temp id ...
            return {
                ...state,
                orderItems_fetchStatus: updateKeys(
                    payload.entityIds,
                    LOADING,
                    state.orderItems_fetchStatus
                ),
            };
        }
        case actionTypes.UPDATE_ORDER_ITEM.SUCCESS:
            return {
                ...state,
                byId: R.mergeDeepRight(state.byId, payload.entities.orders),
                orderItems_byId: R.mergeDeepRight(
                    state.orderItems_byId,
                    payload.entities.orderItems
                ),
                orderItems_fetchStatus: updateKeys(
                    payload.entityIds,
                    LOADED,
                    state.orderItems_fetchStatus
                ),
            };
        case actionTypes.ADD_OFFER.SUCCESS:
            return R.evolve({
                ids: R.union(Object.keys(payload.entities.orders)),
                byId: R.pipe(
                    R.omit([makeTemporaryOrderId(payload.distributionId)]),
                    R.mergeDeepLeft(payload.entities.orders)
                ),
                orderItems_byId: R.mergeDeepLeft(payload.entities.orderItems),
                orderItems_fetchStatus: R.pipe(
                    R.omit(payload.previousEntityIds),
                    updateKeys(payload.entityIds, LOADED)
                ),
            })(state);
        case actionTypes.UPDATE_ORDER_ITEM.FAILURE:
        case actionTypes.ADD_OFFER.FAILURE:
            return {
                ...state,
                orderItems_fetchStatus: updateKeys(
                    payload.entityIds,
                    FAILED,
                    state.orderItems_fetchStatus
                ),
            };
        case actionTypes.VALIDATE_ORDER.REQUEST:
        case actionTypes.REVALIDATE_ORDER.REQUEST:
            return {
                ...state,
                orders_fetchStatus: updateKeys(
                    [payload.orderId],
                    LOADING,
                    state.orders_fetchStatus
                ),
                byId: R.mergeDeepRight(state.byId, {
                    [payload.orderId]: { removedItems: [] },
                }),
            };
        case actionTypes.MISSING_USER_INFORMATION_ERROR:
            return {
                ...state,
                orders_fetchStatus: updateKeys([payload.orderId], LOADED, state.orders_fetchStatus),
            };
        case actionTypes.STOCK_ERROR:
            return {
                ...state,
                byId: R.mergeDeepRight(state.byId, payload.entities.orders),
                orderItems_byId: R.mergeDeepRight(
                    state.orderItems_byId,
                    payload.entities.orderItems
                ),
                orders_fetchStatus: updateKeys([payload.orderId], LOADED, state.orders_fetchStatus),
            };
        case actionTypes.CLOSE_STOCK_ERROR_MODAL:
            return {
                ...state,
                byId: R.mergeDeepRight(state.byId, {
                    [payload.orderId]: {
                        removedItems: [],
                        replacementItems: [],
                    },
                }),
            };
        case actionTypes.VALIDATE_ORDER.FAILURE:
            return {
                ...state,
                orders_fetchStatus: updateKeys([payload.orderId], FAILED, state.orders_fetchStatus),
            };
        case actionTypes.CANCEL_ORDER.REQUEST:
            return {
                ...state,
                orders_fetchStatus: updateKeys(
                    [payload.orderId],
                    LOADING,
                    state.orders_fetchStatus
                ),
            };
        case actionTypes.CANCEL_ORDER.SUCCESS:
            return {
                ...state,
                orders_fetchStatus: updateKeys([payload.orderId], LOADED, state.orders_fetchStatus),
                byId: R.mergeDeepRight(state.byId, {
                    [payload.orderId]: {
                        totalPrice: {
                            amount: 0,
                        },
                        state: 7,
                        isCancelledByTheMember: true,
                    },
                }),
            };
        case actionTypes.CANCEL_ORDER.FAILURE:
            return {
                ...state,
                orders_fetchStatus: updateKeys([payload.orderId], FAILED, state.orders_fetchStatus),
            };
        case actionTypes.RESET_ORDER_TO_CART.REQUEST:
            return {
                ...state,
                orders_fetchStatus: updateKeys(
                    [payload.orderId],
                    LOADING,
                    state.orders_fetchStatus
                ),
            };
        case actionTypes.RESET_ORDER_TO_CART.SUCCESS:
            return {
                ...state,
                orders_fetchStatus: updateKeys([payload.orderId], LOADED, state.orders_fetchStatus),
                byId: R.mergeDeepRight(state.byId, {
                    [payload.orderId]: {
                        state: 2,
                    },
                }),
            };
        case actionTypes.RESET_ORDER_TO_CART.FAILURE:
            return {
                ...state,
                orders_fetchStatus: updateKeys([payload.orderId], FAILED, state.orders_fetchStatus),
            };
        case LOGOUT:
            return ordersCollectionReducer(undefined, {});
        default:
            return state;
    }
};

reducerRegistry.register({
    [GLOBAL_STATE_KEY]: ordersCollectionReducer,
});

export {
    createBasketRequestSent,
    createBasketRequestSucceeded,
    fetchOrders,
    fetchOrdersAndValidateBasket,
    fetchBasket,
    validateOrder,
    revalidateOrder,
    cancelOrder,
    resetOrderToCart,
    editBasketOfferQuantity,
    closeStockAlertModal,
    confirmBasket,
} from './actions';
export {
    getOrders,
    getOrderEntity,
    getOrderItemEntity,
    itemsByFarm,
    isInitialLoad,
    isOrdersCollectionLoading,
    isOrderItemLoading,
    isOrderLoading,
    isOrderFailed,
    getBasketOfDistribution,
    isBasketOfDistributionLoading,
    getQuantityInBasket,
    getQuantityInBasketByOffers,
} from './selectors';
